import React from "react"
import { Row, Col, Container, Button } from "react-bootstrap"
import Layout from "../components/academy-layout"
import Navbar from "../components/academy-layout/navbar"
import SEO from "../components/seo"
import calendar from "../assets/images/icons/calendar.png";
import location from "../assets/images/icons/location.png";
import price from "../assets/images/icons/price.png";
import profile from "../assets/images/icons/profile.png";
import eye from "../assets/images/icons/eye.png";
import afterEye from "../assets/images/icons/after-eye.png";
import medtech from "../assets/images/logo/medtech.png"
import siteminsel from "../assets/images/logo/siteminsel.svg"
import beAdvance from "../assets/images/logo/be-advance.png"
import risklick from "../assets/images/risklick-logo.png"
import bernUni from "../assets/images/logo/bern-uni.png"

class DesignStudio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      minHeight: null
    }
  }

  componentDidMount(){
    this.setState(
      {minHeight: window.innerHeight - 150}
    );

    window.addEventListener("resize", () => {
      this.setState({
        minHeight: window.innerHeight - 150
      })
    });
  }

  render() {
    return (
      <Layout>
        <SEO />
        <Container fluid className={'academy white-bg'}>
          {/*first section*/}
          <Row>
            <Col className='fullHeight academy-intro-section design-studio-bg' style={{height: this.state.minHeight}}>
              <Navbar />
              <Row className={'fullheight sized-container'}>
                <Col xl={5} md={6} lg={7} sm={12} className={'align-middle d-flex align-items-center justify-content-center'}>
                  <Col className={'first-section'} md={{span:10, offset:1}}>
                    <p>
                      Design Studio
                    </p>
                    <p>
                      The studio is a tailor-made workshop bringing your team, experts of the field and AI-based technologies
                      together to design and optimize your Clinical Trial protocol
                    </p>
                  </Col>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* Timer section */}
          <Row>
            <Col className="timer">
              <p>
                22 <sub>Days</sub> : 18 <sub>Hours</sub> : 34 <sub>Minutes</sub> : 45 <sub>Seconds</sub>
                <span>Left to start</span>
              </p>
            </Col>
          </Row>

          {/* Essential Program section */}
          <Row className="essential-program-container sized-container">
            <Col md={8}>
              <p>
                Design Studio
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua Egestas purus viverra accumsan in nisl nisi Arcu cursus vitae congue mauris rhoncus
                aenean vel elit scelerisque In egestas erat imperdiet sed.
              </p>
            </Col>
            <Col className="icons-container" md={{span: 3, offset:1}}>
              <Row>
                <img src={profile} alt=""/>
                <span>Lorem ipsum dolor sit</span>
              </Row>
              <Row>
                <img src={price} alt=""/>
                <span>CHF 1000</span>
              </Row>
              <Row>
                <img src={calendar} alt=""/>
                <span>12-15 May 2022</span>
              </Row>
              <Row>
                <img src={location} alt=""/>
                <span>Lorem ipsum dolor,</span>
              </Row>
              <Row>
                <Button className={"academy-btn mt32"}>
                  Register Now
                </Button>
              </Row>
            </Col>
          </Row>

          {/* advertise */}
          <Row className="studio-course-items">
            <Col md={{span:10, offset: 1}}>
              <Row  className="sized-container">
                <Col xs={{span: 10, offset:1}} md={{span: 10, offset:1}} xl={{span: 7, offset:0}} className="day day1">
                  <div className="left-hexagon hexagon">
                    <div className="red-hexagon" id="hexagon">
                      One Day
                    </div>
                  </div>
                  <p>
                    <img src={eye} alt=""/>
                    Analysis of personal clinical trial protocol by experts
                  </p>
                  <ul>
                    <li>
                      Brainstorming session
                    </li>
                    <li>
                      Experts analysis
                    </li>
                    <li>
                      Comments and discussion
                    </li>
                  </ul>
                  <p className="into-text">
                    Risk analysis of clinical trial protocol
                  </p>
                  <ul>
                    <li>
                      Automated, state-of-the-art Risk analysis of protocol by Risklick AI.
                    </li>
                  </ul>

                  <Col md={{span: 6, offset: 3}} className="separator"></Col>

                  <p>
                    <img src={afterEye} alt=""/>
                    Workshop: developing concept sheet and synopsis
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* logos */}
          <Row className="logo-container sized-container">
            <div className="col">
              <img src={medtech} alt=""/>
            </div>
            <div className="col">
              <img src={siteminsel} alt=""/>
            </div>
            <div className="col">
              <img src={beAdvance} alt=""/>
            </div>
            <div className="col">
              <img src={risklick} alt=""/>
            </div>
            <div className="col">
              <img src={bernUni} alt=""/>
            </div>
          </Row>

        </Container>
      </Layout>
    )
  }
}

export default DesignStudio
